export const getItemFromSessionStorage = (item) => {
  const data = sessionStorage.getItem(item);
  if (data === "undefined") return;
  return JSON.parse(data);
};

export const getItemFromLocalStorage = (item) => {
  const data = localStorage.getItem(item);
  if (data === "undefined") return;
  return JSON.parse(data);
};

//Role Enum
export const Role = {
  Student: 1,
  ResultDigitizer: 2,
  Reviewer: 3,
  Approver: 4,
  Admin: 5,
  SuperAdmin: 6,
};

//Months Utility Object
export const Months = [
  { key: 0, value: "Select Month", disabled: true },
  { key: "1", value: "January" },
  { key: "2", value: "February" },
  { key: "3", value: "March" },
  { key: "4", value: "April" },
  { key: "5", value: "May" },
  { key: "6", value: "June" },
  { key: "7", value: "July" },
  { key: "8", value: "August" },
  { key: "9", value: "September" },
  { key: "10", value: "October" },
  { key: "11", value: "November" },
  { key: "12", value: "December" },
];

export const SidebarLinks = [
  {
    title: "DASHBOARD",
    links: [
      {
        name: "Home",
        icon: "",
        role: [
          "Admin",
          "SuperAdmin",
          "Student",
          "ResultDigitizer",
          "Reviewer",
          "Approver",
          "Busary",
        ],
        to: "home",
      },
    ],
    role: [
      "Admin",
      "SuperAdmin",
      "Student",
      "ResultDigitizer",
      "Reviewer",
      "Approver",
      "Busary",
    ],
  },
  {
    title: "REQUEST",
    links: [
      {
        name: "Transcript",
        icon: "",
        role: ["Student"],
        to: "transcript-request",
      },
      {
        name: "Proficiency in English",
        icon: "",
        role: ["Student"],
        to: "proficiency",
      },
      // {
      //   name: "Verification",
      //   icon: "",
      //   role: ["Student"],
      //   to: "alumi-verification",
      // },
    ],
    role: ["Student"],
  },
  {
    title: "REQUEST MANAGEMENT",
    links: [
      // {
      //   name: "Undigitized",
      //   icon: "",
      //   role: ["TranscriptProcessingAdmin", "Admin", "SuperAdmin"],
      //   to: "awaiting-digitization",
      // },
      // {
      //   name: "Pending",
      //   icon: "",
      //   role: ["TranscriptProcessingAdmin", "Admin", "SuperAdmin", "Reviewer"],
      //   to: "pending-request",
      // },
      // {
      //   name: "Unsigned",
      //   icon: "",
      //   role: ["TranscriptProcessingAdmin", "Admin", "SuperAdmin", "Approver"],
      //   to: "awaiting-signing",
      // },
      {
        name: "Unprocessed Request",
        icon: "",
        role: ["TranscriptProcessingAdmin", "Admin", "SuperAdmin"],
        to: "unprocessed-request",
      },
      {
        name: "Upload Transcript",
        icon: "",
        role: ["TranscriptProcessingAdmin", "Admin", "SuperAdmin"],
        to: "upload-transcript",
      },
      {
        name: "Awaiting Dispatch",
        icon: "",
        role: ["TranscriptProcessingAdmin", "Admin", "SuperAdmin"],
        to: "awaiting-dispatch",
      },
    ],
    role: [
      "TranscriptProcessingAdmin",
      "Admin",
      "SuperAdmin",
      "Approver",
      "Reviewer",
    ],
  },
  {
    title: "DIGITIZATION",
    links: [
      {
        name: "Undigitized",
        icon: "",
        role: ["ResultDigitizer"],
        to: "digitizer_undigitized-requests",
      },
      {
        name: "Flagged",
        icon: "",
        role: ["ResultDigitizer"],
        to: "digitizer_flagged-requests",
      },
      {
        name: "Digitize",
        icon: "",
        role: ["ResultDigitizer"],
        to: "digitizer_digitize",
      },
    ],
    role: ["ResultDigitizer"],
  },
  {
    title: "COURSE",
    links: [
      {
        name: "Create Course",
        icon: "",
        role: ["Admin", "SuperAdmin"],
        to: "create-course",
      },
      {
        name: "Map Course",
        icon: "",
        role: ["Admin", "SuperAdmin"],
        to: "map-course",
      },
    ],
    role: ["Admin", "SuperAdmin"],
  },
  {
    title: "FINANCE",
    links: [
      {
        name: "Pending Requests",
        icon: "",
        role: ["Admin", "SuperAdmin", "Finance", "Busary"],
        to: "finance_pending-requests",
      },
      {
        name: "Flagged Requests",
        icon: "",
        role: ["Admin", "SuperAdmin", "Finance", "Busary"],
        to: "finance_flagged-requests",
      },
      {
        name: "Payment Report",
        icon: "",
        role: ["Admin", "SuperAdmin", "FinanceAdmin"],
        to: "payment_report",
      },
    ],
    role: ["Admin", "SuperAdmin", "Finance", "Busary", "FinanceAdmin"],
  },
];

//ENUMS
export const ResultRequestType = {
  "First Time": 1,
  Reprint: 2,
};
export const ResultType = {
  "Hard Copy": 1,
  "Soft Copy": 2,
};
export const ResultStatus = {
  0: "Not Paid",
  1: "Paid",
  2: "Processing",
  3: "Ready For Review",
  4: "Ready For Approval",
  5: "Awaiting Signing",
  6: "Awaiting Approval",
  7: "Ready For Dispatch",
  8: "Dispatched",
  9: "Flagged",
};
export const DocumentType = {
  Clearance: 1,
  paymentReciept: 2,
};
export const CouriersType = {
  DHL: 1,
  FEDEX: 2,
};
export const TRANSCRIPT_USE_CASE = {
  1: "Official Copy",
  2: "Student Copy",
};

export const DELIVERY_USE_CASE = {
  1: "Hard Copy",
  2: "Soft Copy(Email)",
};
export const TRANSCRIPT_USE_CASE_ENUM = {
  "Official Use": 1,
  "Student Use": 2,
};
